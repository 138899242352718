<template>
    <div id="purchaseListPage">
      <div class="top-nav-bar">
        <van-nav-bar :title="$route.meta.title" @click-left="$router.back()" @click-right="onDelete()">
          <van-icon class="back-arrow" name="arrow-left"  slot="left" />
          <div class="right" slot="right">
            <i class="icon icon-clear"></i>
            <span class="name">クリア</span>
          </div>
        </van-nav-bar>
      </div>
      <div class="container" >
        <Empty
          v-if="detail.totalCount === 0"
          tip="購入した動画がありません"
        ></Empty>
        <van-list
          v-model="detail.loading"
          :finished="detail.finished"
          loading-text="ローディング..."
          @load="onLoad"
          :offset="30"
        >
          <van-row class="list" type="flex">
            <van-col
              v-for="item in detail.list"
              :span="span"
              :key="item.id"
            >
              <router-link
                :to="{name: 'Detail',query: { id: item.id } }"
                class="list-item"
              >
                <ListItem2 :detail="item">
                  <template slot="right">
                    <i @click.prevent="onDelete(item)" class="icon icon-del"></i>
                  </template>
                </ListItem2>
              </router-link>
            </van-col>
          </van-row>
        </van-list>
      </div>
    </div>
</template>

<script>
import { NavBar } from 'vant';
import { Empty, ListItem2 } from '@/components';
import { mapGetters } from 'vuex';
export default {
  name: 'PurchaseList',
  components: {
    'van-nav-bar': NavBar,
    Empty,
    ListItem2
  },
  data () {
    return {
      span: 24,
      detail: {
        list: [],
        pageSize: 20,
        pageNo: 1,
        loading: false,
        finished: false,
        totalCount: ''
      }
    };
  },
  computed: {
    ...mapGetters(['htmlWidth'])
  },
  created () {
  },
  mounted () {
    this.onResize();
  },
  watch: {
    htmlWidth () {
      this.onResize();
    }
  },
  methods: {

    /* 页面加载事件 */
    onLoad () {
      let { detail } = this;
      let { pageNo, pageSize } = detail;
      this.$http.get('mine/buyList', {
        params: {
          pageNo,
          pageSize
        }
      }).then((res) => {
        let { nextPage, result, isHasNext, totalCount } = res.data || {};
        detail.list.push(...result);
        detail.pageNo = nextPage;
        detail.totalCount = totalCount;
        detail.loading = false;
        detail.finished = !isHasNext;
      });
    },

    /* 初始化数据  tab栏切换时调用 */
    init () {
      this.detail = {
        list: [],
        pageSize: 20,
        pageNo: 1, // 当前页
        loading: false,
        finished: false,
        totalCount: ''
      };
    },

    /* 删除购买视频 */
    onDelete (item) {
      const { $dialog, $utils, $http } = this;
      $dialog.confirm({
        title: 'ご注意',
        message: item ? '确定删除本次购买记录？' : '确定清除全部购买记录？',
        confirmButtonText: '決定',
        cancelButtonText: 'キャンセル',
        closeOnClickOverlay: true
      }).then(() => {
        if (item) {
          $http.get('mine/remove/buy/record', {
            params: {
              id: item.orderId // 待确认是orderId还是id
            }
          }).then((res) => {
            this.init();
            $utils.successToast({
              message: '削除済み'
            });
          });
        } else {
          $http.get('mine/clear/buy/record').then((res) => {
            this.init();
            $utils.successToast({
              message: '削除済み'
            });
          });
        }


      });
    },

    /* 控制屏幕大小 */
    onResize () {
      const { htmlWidth } = this;
      this.span = 24;
      if (htmlWidth > 1200) {
        this.span = 8;
      } else if (htmlWidth > 992) {
        this.span = 12;
      }
    }

  }
};
</script>

<style scoped lang="less">
  #purchaseListPage {
    display: flex;
    flex-direction: column;
    height: 100%;

    /deep/
    .top-nav-bar {
      .van-nav-bar {
        .right {
          display: flex;
          align-items: center;

          .icon {
            width: 32px;
            height: 32px;

            &.icon-clear {
              background-image: url("~@/assets/images/icon_clear_01.png");
            }
          }

          .name {
            margin-left: 12px;
            font-size: 24px;
            line-height: 32px;
          }
        }
      }
    }

    .container {
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;

      .list {
        .list-item {
          position: relative;
          width: 100%;
          padding: 28px;

          &::after {
            position: absolute;
            bottom: 0;
            left: 28px;
            width: calc(100% - 56px);
            height: 1px;
            content: '';
            background-color: rgba(255, 255, 255, 0.05);
          }

          .icon-del {
            width: 36px;
            height: 36px;
            background-image: url("~@/assets/images/icon_delete_01.png");
          }
        }
      }
    }
  }
</style>